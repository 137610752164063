body {
  background-color: rgb(219, 218, 214)  !important; 
  color: rgb(153, 153, 153);
  color: rgb(55, 53, 47);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.8rem;
}

a {
  color: #33669A;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 200;
  line-height: 1.2;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


.navbar-light .navbar-nav .nav-link {
  /* color: #33669A !important; */
  color: white !important; 
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .main-logo {
      background-image: url('HomeMoney_logo_small@2x.png');
      background-size: 199px 35px;
      display: inline-block;
      width: 199px;
      height: 35px;
  }
}
.main-logo-text {
  position: relative;
  top: -9px; 
  color: #ada491;
  font-size: 13px;
}
.main-landing h1 {
  font-weight: 300;
}
.main-landing h2 {
  padding: 3rem 0 0rem 0;
  font-weight: 200;
}
.main-landing h3 {
  font-size: 1.5em;
  padding: 1rem 0 0 0;
  font-weight: 400;
}
.main-landing h4 {
  font-size: 1.2em;
  padding: 1rem 0 0 0;
  font-weight: 400;
}
.main-landing .landing-top {
  background-color: #666;
  border-radius: 5px;
  padding: 10rem 2rem 2rem;
  margin: -10rem -2rem 0rem;
  color: white;
}
.main-landing .features {
  column-width: 300px;
  column-gap: 20px;
}
.main-landing .feature {
  break-inside: avoid;
  page-break-inside: avoid;
}

.main-landing .userfeedback {
  column-width: 300px;
  column-gap: 20px;
}

.main-landing .userfeedback .box {
  break-inside: avoid;
  page-break-inside: avoid;
  margin-bottom: 1rem;
}
.box {
  padding: 1rem;
  background-color: White;
  border-radius: 5px;
}
.navbar {
  padding: 1rem 1rem;
}
.footer {
  padding: 3rem 0rem;
}

.footer a {
  padding-left: 1.5rem;
  color: #999;
}