a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    font-weight: 200;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.border-bottom {
    border-bottom: none !important;
}

